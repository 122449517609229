import { BASE_URL } from "../constants";
import {
  ApiResponse,
  DeleteOptions,
  ErrorResponse,
  GetOptions,
  PostOptions,
  PutOptions,
  RequestOptions,
} from "../types";
import qs from "query-string";

class Client {
  private static async request<T = unknown>({
    path,
    headers,
    body,
    query = {},
    method = "GET",
  }: RequestOptions): Promise<ApiResponse<T>> {
    try {
      //Request URL
      const url = qs.stringifyUrl({
        url: `${BASE_URL}${path}`,
        query,
      });
      //Send request
      const response = await fetch(url, {
        headers,
        method,
        body: JSON.stringify(body),
      });
      //Response
      return {
        ok: response.ok,
        statusCode: response.status,
        ...(response.ok
          ? { data: await response.json() } //Successfull
          : { error: await response.text() }), //Error
      } as ApiResponse<T>;
    } catch (err) {
      //Unresolved error (eg. Network disconnection)
      return {
        ok: false,
        error: err,
      } as ErrorResponse;
    }
  }

  static async get<T = unknown>(params: GetOptions) {
    const response = await this.request<T>({ ...params, method: "GET" });
    return response;
  }

  static async post<T = unknown>(params: PostOptions) {
    const response = await this.request<T>({
      ...params,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...params.headers,
      },
    });
    return response;
  }

  static async put<T = unknown>(params: PutOptions) {
    const response = await this.request<T>({
      ...params,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...params.headers,
      },
    });
    return response;
  }

  static async delete<T = unknown>(params: DeleteOptions) {
    const response = await this.request<T>({ ...params, method: "DELETE" });
    return response;
  }
}

export default Client;
