import type { AppProps } from "next/app";
import DefaultSeo from "src/components/reusable/headless/DefaultSeo";
import Gtag from "src/components/reusable/headless/Gtag";
import PrimaryLayout from "src/layouts/Primary";
import { ChakraProvider, SWRProvider, ProjectsProvider } from "src/providers";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <SWRProvider>
      <ChakraProvider>
        <PrimaryLayout>
          <ProjectsProvider>
            <Gtag />
            <DefaultSeo />
            <Component {...pageProps} />
          </ProjectsProvider>
        </PrimaryLayout>
      </ChakraProvider>
    </SWRProvider>
  );
}

export default MyApp;
