import ScaleGenerator from "./base";

class PrimaryGenerator extends ScaleGenerator {
  constructor(length: number) {
    super(
      process.env.NEXT_PUBLIC_PRIMARY_SCALE_MIN ?? "",
      process.env.NEXT_PUBLIC_PRIMARY_SCALE_MAX ?? "",
      length
    );
  }
}

export default PrimaryGenerator;
