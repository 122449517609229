import React from "react";
import { SWRConfig } from "swr";
import fetcher from "./fetcher";

interface Props {
  children?: React.ReactNode;
}

const Provider: React.FC<Props> = ({ children }) => {
  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>;
};

export default Provider;
