import { ChakraTheme, extendTheme } from "@chakra-ui/react";
import breakpoints from "./breakpoints";
import colors from "./colors";
import fonts from "./fonts";
import styles from "./styles";

const theme = extendTheme({
  colors,
  fonts,
  breakpoints,
  styles,
}) as ChakraTheme;

export default theme;
