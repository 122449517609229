import { Box } from "@chakra-ui/react";
import React from "react";
import Scrollbar from "src/components/reusable/headless/Scrollbar";
import Toaster from "src/components/reusable/headless/Toaster";

interface Props {
  children?: React.ReactNode;
}

const Primary: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Scrollbar width={5} trackColor="#f1f1f1" handleColor="#c7c1c1" />
      <Toaster />
      <Box
        overflow="hidden"
        position="relative"
        paddingInline={{ base: 8, lg: "10vw" }}
      >
        {children}
      </Box>
    </>
  );
};

export default Primary;
