import chroma from "chroma-js";

class ScaleGenerator {
  protected minColor: string; //beginning of the scale
  protected maxColor: string; //end of the scale
  private length: number; //scale length

  constructor(minColor: string, maxColor: string, length: number) {
    this.minColor = minColor;
    this.maxColor = maxColor;
    this.length = length;
  }

  generate() {
    const scale = chroma
      .scale([this.minColor, this.maxColor])
      .mode("lch")
      .colors(this.length);
    return scale;
  }
}

export default ScaleGenerator;
