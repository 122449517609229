import { PrimaryGenerator } from "./scale-generators";
import chroma from "chroma-js";

class ChromaHelper {
  static generatePrimaryScale(length: number) {
    const generator = new PrimaryGenerator(length);
    const scale = generator.generate();
    return scale;
  }

  static darkenColor(color: string, amount = 1) {
    return chroma(color).darken(amount).hex();
  }

  static lightenColor(color: string, amount = 1) {
    return chroma(color).brighten(amount).hex();
  }
}

export default ChromaHelper;
