import { ChakraTheme } from "@chakra-ui/react";
import { ChromaHelper } from "src/helpers/theme";

//Generates a chakra color scheme from array of strings (chroma scale)
const generateChakraColorScheme = (colors: string[]) => {
  return Object.fromEntries(
    colors.map((color, idx) => [(idx + 1) * 100, color])
  );
};

const colors = {
  primary: generateChakraColorScheme(ChromaHelper.generatePrimaryScale(9)),
} as ChakraTheme["colors"];

export default colors;
