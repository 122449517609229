import { Box } from "@chakra-ui/react";
import React from "react";
import { Toaster as ReactToaster } from "react-hot-toast";

const Toaster = () => {
  return (
    <Box
      css={`
        .global-toaster div[role="status"] {
          margin: 0 10px;
        }
      `}
    >
      <ReactToaster
        position="bottom-left"
        toastOptions={{
          style: {
            color: "gray",
            alignItems: "flex-start",
          },
        }}
        containerClassName="global-toaster"
        containerStyle={{ paddingBlock: "4px" }}
      />
    </Box>
  );
};

export default Toaster;
