import React from "react";
import { Global, css } from "@emotion/react";
import type { Props } from "./types";
import { ChromaHelper } from "src/helpers/theme";

const Scrollbar: React.FC<Props> = ({ width, trackColor, handleColor }) => {
  return (
    <Global
      styles={css`
        ::-webkit-scrollbar {
          width: ${width}px;
        }

        ::-webkit-scrollbar-track {
          background: ${trackColor};
        }

        ::-webkit-scrollbar-thumb {
          background: ${handleColor};
        }

        ::-webkit-scrollbar-thumb:hover {
          background: ${ChromaHelper.darkenColor(handleColor)};
        }
      `}
    />
  );
};

export * from "./types";
export default Scrollbar;
