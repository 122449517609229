/* Header */
export const HEADER_HEADLINE = "Eldababa Portfolio";
export const HEADER_SUBLINE =
  "A modern progressive web application built using cutting edge technologies";
export const HEADER_BUTTON_TEXT = "Explore It";

/* About */
export const ABOUT_HEADLINE = "Who am I?";
export const ABOUT_CONTENT =
  "I'm Abdulrahman. An undergraduate self-taught Full-Stack JS (MERN-Stack) developer with over 3 years of experience in developing scalable, mantainable, and highly available Full-Stack progressive single page applications (SPAs) and web applications using latest, most modern, and cutting-edge technologies and trends.";

/* Skills */
export const SKILLS_HEADLINE = "My Skills";
export const SKILLS_SUBLINE = "Only a few highlighted ones..";

/* Benefits */
export const BENEIFTS_HEADLINE = "Why Choose Me?";
export const BENEFITS_QUOTE =
  "“I’m convinced that nothing we do is more important than hiring and developing people. At the end of the day you bet on people, not on strategies.” – Lawrence Bossidy";

/* Testimonials */
export const TESTIMONIALS_HEADLINE = "Testimonials";
export const TESTIMONIALS_SUBLINE = "What my lovely clients said..";

/* Contact */
export const CONTACT_HEADLINE = "Get In Touch";
export const CONTACT_DESC = "Want something done? \nJust send me an email..";
export const CONTACT_EMAIL = "Your email address";
export const CONTACT_MESSAGE = "Write whatever you want here...";
export const CONTACT_BUTTON_TEXT = "Send";
export const CONTACT_SUCCESS =
  "Thank you. I've received your message and will get back to you asap!";
export const CONTACT_ERROR = "Sorry. Couldn't send your message..";

/* Footer */
export const FOOTER_CV_ERROR = "Couldn't fetch CV";

/* Search */
export const SEARCH_PLACEHOLDER = "Search projects...";

/* Projects */
export const PROJECTS_FETCH_ERROR = "Woops. Wasn't able to fetch projects..";
