import { ChakraTheme } from "@chakra-ui/react";

const styles = {
  global: {
    body: {
      overflowX: "hidden",
    },
  },
} as ChakraTheme["styles"];

export default styles;
