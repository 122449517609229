import { useDisclosure } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import React from "react";
import { ProjectsContext, ProviderProps } from "./types";

const ProjectsModal = dynamic(() => import("./Modal"));

//Initial context state
const initialState = {
  show: () => {},
  hide: () => {},
  toggle: () => {},
} as ProjectsContext;

//Contenxt
export const Context = React.createContext<ProjectsContext>(initialState);

//Provider
const ProjectsProvider: React.FC<ProviderProps> = ({ children }) => {
  //Modal disclosure
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

  //Context value
  const value = {
    open: isOpen,
    show: onOpen,
    hide: onClose,
    toggle: onToggle,
  };

  return (
    <Context.Provider value={value}>
      <ProjectsModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        onToggle={onToggle}
      />
      {children}
    </Context.Provider>
  );
};

export default ProjectsProvider;
