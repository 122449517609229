import { ChakraTheme } from "@chakra-ui/react";
import { breakpoints as BREAKPOINTS } from "src/constants";

const breakpoints = {
  base: "0em",
  xs: `${BREAKPOINTS.xs}px`,
  sm: `${BREAKPOINTS.sm}px`,
  md: `${BREAKPOINTS.md}px`,
  lg: `${BREAKPOINTS.lg}px`,
  xl: `${BREAKPOINTS.xl}px`,
} as ChakraTheme["breakpoints"];

export default breakpoints;
