import React from "react";
import Script from "next/script";

const Gtag = () => {
  //Gtag ID
  const GA_MEASURMENT_ID = process.env.NEXT_PUBLIC_GA_MEASURMENT_ID;

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASURMENT_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${GA_MEASURMENT_ID}');
    `}
      </Script>
    </>
  );
};

export default Gtag;
