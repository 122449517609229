/* Basic */
export const DEPLOYMENT_URL = "https://eldababa-portfolio.vercel.app";
export const THEME_COLOR = "#3E71A0";

/* SEO */
export const SEO_TITLE =
  "Eldababa Portfolio: A Modern Progressive Web Application";
export const SEO_DESCRIPTION =
  "A modern progressive single page web application built using latest cutting-edge technologies and trends in web & software development.";
export const SEO_KEYWORDS =
  "Eldababa, Portfolio, MERN, Node, NodeJS, Full-Stack, Nest, NestJS, Express, Mongo, MongoDB, Web, Developer";
export const SEO_AUTHOR = "Abdu";
