import React from "react";
import { DefaultSeo as NextDefaultSeo } from "next-seo";
import { SEO_TITLE, THEME_COLOR } from "src/constants";

const DefaultSeo = () => {
  return (
    <NextDefaultSeo
      additionalMetaTags={[
        {
          name: "application-name",
          content: SEO_TITLE,
        },
        {
          name: "theme-color",
          content: THEME_COLOR,
        },
        {
          name: "google-site-verification",
          content: "OXsstpiCkg7XmFuIQ2h0Y2a0OpO2cLqn6NZZR_LsGnc",
        },
      ]}
      additionalLinkTags={[
        {
          rel: "manifest",
          href: "/manifest.json",
        },
        {
          rel: "apple-touch-icon",
          sizes: "192x192",
          href: "/logo192.png",
        },
        {
          rel: "shortcut icon",
          href: "/favicon.ico",
          type: "image/x-icon",
        },
        {
          rel: "preconnect",
          href: "https://fonts.googleapis.com",
        },
        {
          rel: "preconnect",
          href: "https://fonts.gstatic.com",
          crossOrigin: "",
        },
        {
          rel: "preload",
          href: "https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;700&display=swap",
          as: "style",
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;700&display=swap",
        },
        {
          rel: "canonical",
          href: "https://www.eldababa.dev/",
        },
      ]}
    />
  );
};

export default DefaultSeo;
