import React from "react";
import { ChakraProvider, LightMode } from "@chakra-ui/react";
import theme from "./theme";

interface Props {
  children?: React.ReactNode;
}

const Provider: React.FC<Props> = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <LightMode>{children}</LightMode>
    </ChakraProvider>
  );
};

export default Provider;
